import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "game-loop-animation"
    }}>{`Game loop animation`}</h1>
    <p>{`Our general approach to animation goes like this: Render from state. Change state 60 times per second. Animation!`}</p>
    <p>{`We're going to use two different ways of changing state so often. The first follows a game loop principle, which gives you more control, but is more tedious. The second is using D3 transitions, which is quicker to build, but gives you less control.`}</p>
    <p>{`We're going to start with an example or two in CodeSandbox, then build something more involved.`}</p>
    <h2 {...{
      "id": "using-a-game-loop-for-rich-animation"
    }}>{`Using a game loop for rich animation`}</h2>
    <p>{`I love game loops. It even sounds fun "game loop". Maybe it's just that whenever I build a game loop, the thing I'm building is fun to play with. 🤔`}</p>
    <p>{`A game loop is an infinite loop where each iteration renders the next frame of your game or animation. You do your best to complete each iteration in 16 milliseconds and your user gets smooth animation.`}</p>
    <p>{`As you can imagine, our challenge is to cram all physics and rendering into those 16 milliseconds. The more elements you're rendering, the harder it gets.`}</p>
    <h3 {...{
      "id": "a-bouncing-ball"
    }}>{`A bouncing ball`}</h3>
    <p>{`Let's get our feet wet with my favorite childhood example: a bouncing ball.`}</p>
    <p>{`I must have built dozens of them back in my Turbo Pascal days using BGI. Yes, those Turbo Pascal and BGI are from the 80's. No, I'm not that old, I just started young and with old equipment. Coding for DOS is easier when you're a kid than coding for Windows 95.`}</p>
    <p>{`Here's a sandbox I prepared for you earlier:`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/d3-bouncing-ball-hooks-unsolved-eotik",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`Build a bouncy ball :)`}</p>
    <p>{`Remember that gravity is an acceleration pointing down. It impacts the `}<em parentName="p">{`speed`}</em>{` of your ball, not its position directly.`}</p>
    <ol>
      <li parentName="ol">{`Render the ball`}</li>
      <li parentName="ol">{`Use an effect to start a timer with `}<inlineCode parentName="li">{`d3.timer`}</inlineCode>{` (it's like setInterval but better)`}</li>
      <li parentName="ol">{`Ensure you clean up with `}<inlineCode parentName="li">{`timer.stop()`}</inlineCode></li>
      <li parentName="ol">{`Move the ball on each timer iteration (also known as a tick)`}</li>
      <li parentName="ol">{`Adjust the ball's speed to simulate gravity`}</li>
    </ol>
    <p>{`How do you get the bounce effect at `}<inlineCode parentName="p">{`max_h`}</inlineCode>{`? Look at the direction `}<inlineCode parentName="p">{`vY`}</inlineCode>{` points.`}</p>
    <p>{`Here's my solution`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/d3-bouncing-ball-hooks-unsolved-5l05f",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      